import { Button, ListGroup } from "react-bootstrap";

import translateDate from "../../../Utils/translateDate";
import { MapSettings } from "../../../services/models/map_settings";

interface Props {
  mapSettings: MapSettings;
  onSelectIndicator: () => void;
}

export const MapSettingsSelectedIndicator = ({ mapSettings, onSelectIndicator }: Props) => {
  const { tag, module, value } = mapSettings;

  if (!tag?.id || !module?.id || !value?.id) {
    return null;
  }

  return (
    <div>
      <h5 className="text-center">Выбранный показатель</h5>

      <ListGroup style={{ width: 600 }}>
        <ListGroup.Item variant="primary">
          {tag?.title} - {translateDate(tag?.date)}
        </ListGroup.Item>
        <ListGroup.Item variant="secondary">
          {"->"} {module?.title || "Название не указано"}
        </ListGroup.Item>
        <ListGroup.Item variant="secondary">
          {"--->"} {value?.title || "Название не указано"}
        </ListGroup.Item>
      </ListGroup>

      <div className="mt-3 text-center">
        <Button onClick={onSelectIndicator}>Выбрать показатель</Button>
      </div>
    </div>
  );
};
