export const statisticColors = ["#ba550d", "#c8752d", "#d59546", "#e0b45e", "#225931"];

export const chartAverageOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Рейтинг по годам",
    },
  },
  scales: {
    x: {
      offset: true,
    },
    y: {
      offset: true,
    },
  },
};

export const chartDataInitState = {
  labels: ["2024", "2025", "2026"],
  datasets: [],
};

export const chartDataWithDatasetsInitState = {
  labels: ["2024", "2025", "2026"],
  datasets: [
    {
      label: "Среднее по субъектам РФ",
      data: [0, 0.4, 0.2],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
      fill: false,
    },
  ],
};
