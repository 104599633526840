import { MapSettings, MapSettingsCreate } from "../models/map_settings";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchMapSettings(): Promise<MapSettings | null> {
  const response = await fetchData("/map_settings", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

export async function updateMapSettings(mapSettings: MapSettingsCreate): Promise<MapSettings> {
  const response = await fetchData("/map_settings", {
    method: "PUT",
    headers: {
      Authorization: authToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(mapSettings),
  });
  return response.json();
}
