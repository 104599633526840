import { useQuery } from "@tanstack/react-query";

import { fetchRoles } from "../../services/network/role-api";

export function useFetchRoles() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/roles"],
    queryFn: () => fetchRoles(),
  });

  return {
    rolesData: data,
    rolesError: error,
    rolesIsLoading: isLoading,
  };
}
