import { Button, Card } from "react-bootstrap";
import { SubUser } from "../../../services/models/sub_user";
import styles from "../../../assets/styles/ObserverCuratorsGrid.module.css";
import { Role } from "../../../services/models/role";
import { Sector } from "../../../services/models/sector";

interface SubUserCardProps {
  subUser: SubUser;
  roles: Role[];
  sectors: Sector[];
  onUpdateSubUserClicked?: (subUser: SubUser) => void;
  onDeleteSubUserClicked?: (subUser: SubUser) => void;
  isEditable?: boolean;
}

const SubUserCard = ({
  subUser,
  roles,
  sectors,
  onUpdateSubUserClicked,
  onDeleteSubUserClicked,
  isEditable = true,
}: SubUserCardProps) => {
  return (
    <Card>
      <Card.Body>
        <Card.Text className={styles.curatorNameField}>
          <div>{subUser.full_name}</div>
        </Card.Text>
        <Card.Text className={styles.subUserRolesField}>
          <div>Роли: </div>
          {roles.length > 0 ? (
            <div className={styles.roles}>
              {roles.map((role) => (
                <div>{role.name}</div>
              ))}
            </div>
          ) : (
            <div>Роли не указаны</div>
          )}
        </Card.Text>
        <Card.Text className={styles.subUserSectorsField}>
          <div>Субъекты РФ:</div>
          {sectors.length > 0 ? (
            <div className={styles.sectors}>
              {sectors.map((sector) => (
                <div>{sector.name}</div>
              ))}
            </div>
          ) : (
            <div>Субъекты РФ не указаны</div>
          )}
        </Card.Text>
        <Card.Text className={styles.curatorNameField}>
          <div>Почта:</div>
          <a href={`mailto:${subUser?.email}`}>{subUser.email}</a>
        </Card.Text>
      </Card.Body>

      {isEditable && (
        <Card.Footer className={styles.curatorNameField}>
          {onUpdateSubUserClicked && (
            <Button
              onClick={(e) => {
                onUpdateSubUserClicked(subUser);
                e.stopPropagation();
              }}
            >
              Редактировать
            </Button>
          )}

          {onDeleteSubUserClicked && (
            <Button
              variant="danger"
              onClick={(e) => {
                onDeleteSubUserClicked(subUser);
                e.stopPropagation();
              }}
            >
              Удалить
            </Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default SubUserCard;
