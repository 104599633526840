import { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";

import { MapSettingsColor } from "../../../services/models/map_settings";
import { MapSettingsColorPicker } from "./MapSettingsColorPicker";
import styles from "./MapSettingsColorBlock.module.css";

interface Props {
  colors: MapSettingsColor[];
  onSave: (colors: MapSettingsColor[]) => void;
}

interface ChangeColorState {
  color: string;
  index: number;
}

interface ErrorState {
  [index: number]: {
    minValue?: boolean;
    maxValue?: boolean;
  };
}

export const MapSettingsColorBlock = ({ colors, onSave }: Props) => {
  const [ranges, setRanges] = useState<MapSettingsColor[]>([]);
  const [changeColor, setChangeColor] = useState<ChangeColorState | null>(null);
  const [errors, setErrors] = useState<ErrorState>({});

  useEffect(() => {
    setRanges(colors);
  }, [colors]);

  const validateRange = (index: number) => {
    const newErrors = { ...errors };
    const currentRange = ranges[index];

    // Проверка minValue
    if (currentRange.minValue > currentRange.maxValue) {
      newErrors[index] = { ...newErrors[index], minValue: true };
    } else if (index > 0 && currentRange.minValue < ranges[index - 1].maxValue) {
      newErrors[index] = { ...newErrors[index], minValue: true };
    } else {
      if (newErrors[index]) delete newErrors[index].minValue;
    }

    // Проверка maxValue
    if (currentRange.maxValue < currentRange.minValue) {
      newErrors[index] = { ...newErrors[index], maxValue: true };
    } else if (index < ranges.length - 1 && currentRange.maxValue > ranges[index + 1].minValue) {
      newErrors[index] = { ...newErrors[index], maxValue: true };
    } else {
      if (newErrors[index]) {
        delete newErrors[index].maxValue;
      }
    }

    if (!Object.keys(newErrors[index] || {}).length) {
      delete newErrors[index];
    }

    setErrors(newErrors);
  };

  const handleChangeRange = (index: number, field: keyof Omit<MapSettingsColor, "color">, value: string) => {
    const newRanges = [...ranges];
    newRanges[index] = {
      ...newRanges[index],
      [field]: value === "" ? "" : Number(value),
    };
    setRanges(newRanges);
  };

  const handleAddRange = () => {
    const lastRange = ranges[ranges.length - 1];
    const newRange: MapSettingsColor = {
      minValue: lastRange ? lastRange.maxValue : 0,
      maxValue: lastRange ? lastRange.maxValue + 1 : 1,
      color: "#ffffff",
    };

    setRanges([...ranges, newRange]);
  };

  const handleRemoveRange = (index: number) => {
    const newRanges = ranges.filter((_, i) => i !== index);
    const newErrors = { ...errors };

    delete newErrors[index];

    const updatedErrors: ErrorState = {};
    Object.entries(newErrors).forEach(([key, value]) => {
      const newIndex = parseInt(key, 10);
      if (newIndex > index) {
        updatedErrors[newIndex - 1] = value;
      } else {
        updatedErrors[newIndex] = value;
      }
    });

    setRanges(newRanges);
    setErrors(updatedErrors);
  };

  const handleChangeColor = (color: string) => {
    if (changeColor) {
      const newRanges = [...ranges];
      newRanges[changeColor.index] = {
        ...newRanges[changeColor.index],
        color,
      };
      setRanges(newRanges);
      setChangeColor(null);
    }
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-2">
        {ranges.map((range, index) => (
          <Card key={index} style={{ backgroundColor: range.color }}>
            <Card.Body>
              <div className="d-flex gap-3 align-items-center">
                <Form.Group>
                  <Form.Label>От:</Form.Label>
                  <Form.Control
                    type="number"
                    value={range.minValue}
                    step={0.1}
                    onChange={(e) => handleChangeRange(index, "minValue", e.target.value)}
                    onBlur={() => validateRange(index)}
                    className={errors[index]?.minValue ? styles.error : ""}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>До:</Form.Label>
                  <Form.Control
                    type="number"
                    value={range.maxValue}
                    step={0.1}
                    onChange={(e) => handleChangeRange(index, "maxValue", e.target.value)}
                    onBlur={() => validateRange(index)}
                    className={errors[index]?.maxValue ? styles.error : ""}
                  />
                </Form.Group>

                <div className="d-flex flex-column gap-2">
                  <Button
                    variant="primary"
                    onClick={() => setChangeColor({ color: range.color, index })}
                    className="ms-auto w-100"
                  >
                    Цвет
                  </Button>
                  <Button variant="danger" onClick={() => handleRemoveRange(index)} className="ms-auto w-100">
                    Удалить
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <Button onClick={handleAddRange}>Добавить диапазон</Button>

      <div className="d-flex gap-3 mt-3 mb-5">
        <Button onClick={() => setRanges(colors)} className="w-100" variant="danger">
          Сбросить
        </Button>
        <Button
          onClick={() => onSave(ranges)}
          className="w-100"
          variant="success"
          disabled={Object.keys(errors).length > 0}
        >
          Сохранить
        </Button>
      </div>

      <MapSettingsColorPicker
        isShowModal={!!changeColor}
        currentColor={changeColor?.color}
        onSave={(color) => handleChangeColor(color)}
        onClose={() => setChangeColor(null)}
      />
    </div>
  );
};
