import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Sector, SectorInfoPollTags } from "../../../services/models/sector";
import { Poll as PollModel } from "../../../services/models/poll";
import Poll from "../../Poll";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";

interface Props {
  sector: Sector;
  polls: PollModel[];
  pollTags: SectorInfoPollTags[];
}

interface PollList extends PollModel {
  pollTemplateId: string;
}

export const SectorPolls = ({ sector, polls, pollTags }: Props) => {
  const navigate = useNavigate();
  const [pollsList, setPollsList] = useState<PollList[]>([]);

  useEffect(() => {
    if (polls?.length && pollTags?.length) {
      const pollList = polls.map((poll) => ({
        ...poll,
        pollTemplateId: pollTags.find((tag) => tag.tagId === poll.tag)?.pollTemplateId ?? "",
      }));

      setPollsList(pollList.filter((poll) => poll.pollTemplateId));
    }
  }, [polls, pollTags]);

  return (
    <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
      {pollsList.map((poll) => (
        <Col key={poll.id}>
          <Poll
            poll={poll}
            sector={sector}
            onPollResultClicked={() =>
              navigate(`/administrator/cabinet/poll_templates/${poll.pollTemplateId}/${poll.tag}/${poll.id}/result`)
            }
            className={styles.project}
          />
        </Col>
      ))}
    </Row>
  );
};
