import { Container } from "react-bootstrap";

import { MapSettingsPageView } from "./MapSettingsPageView";
import styles from "../../../assets/styles/ProjectPage.module.css";

const MapSettingsPage = () => {
  return (
    <Container className={styles.projectPage}>
      <MapSettingsPageView />
    </Container>
  );
};

export default MapSettingsPage;
