import { Sector, SectorInfo } from "../models/sector";
import { fetchData } from "../../Utils/fetchData";
import authToken from "../../Utils/authToken";

export async function fetchSectors(): Promise<Sector[]> {
  const response = await fetchData("/sector", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

export async function fetchSectorsInfo(): Promise<Sector[]> {
  const response = await fetchData("/sector", {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });

  return response.json();
}

interface fetchSectorsByDistrictProps {
  district_id: string;
}

export async function fetchSectorsByDistrict(request: fetchSectorsByDistrictProps): Promise<Sector[]> {
  const response = await fetchData("/sector", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken(),
    },
    body: JSON.stringify(request),
  });
  return response.json();
}

export async function fetchSector(sectorId: string): Promise<Sector> {
  const response = await fetchData("/sector/" + sectorId, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export async function fetchSectorInfo(sectorId: string): Promise<SectorInfo> {
  const response = await fetchData(`/sector/${sectorId}/info`, {
    method: "GET",
    headers: {
      Authorization: authToken(),
    },
  });
  return response.json();
}

export interface SectorInputProps {
  name: string;
  district: string;
}

// export async function createSector(sector: SectorInputProps): Promise<Sector> {
//   const response = await fetchData("/sector", {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: authToken(),
//     },
//     body: JSON.stringify(sector),
//   });
//   return response.json();
// }

// export async function updateSector(sectorId: string, sector: SectorInputProps): Promise<Sector> {
//   const response = await fetchData("/sector/" + sectorId, {
//     method: "POST",
//     headers: {
//       "Content-type": "application/json",
//       Authorization: authToken(),
//     },
//     body: JSON.stringify(sector),
//   });
//   return response.json();
// }

// export async function deleteSector(sectorId: string) {
//   await fetchData("/sector/" + sectorId, {
//     method: "DELETE",
//     headers: {
//       Authorization: authToken(),
//     },
//   });
// }
