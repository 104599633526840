import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import { Sector } from "../../../services/models/sector";

interface SectorCardProps {
  sector: Sector;
  className?: string;
}

const SectorCard = ({ sector, className }: SectorCardProps) => {
  const { name } = sector;
  return (
    <Card className={`${styles.projectCard} ${className}`}>
      <Card.Body className={styles.cardBody}>
        <Card.Title className="mb-0">{name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default SectorCard;
