import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { HexColorPicker } from "react-colorful";

interface Props {
  isShowModal: boolean;
  currentColor?: string;
  onClose: () => void;
  onSave: (color: string) => void;
}

export const MapSettingsColorPicker = ({ isShowModal, currentColor, onClose, onSave }: Props) => {
  const [color, setColor] = useState("#ffffff");

  useEffect(() => {
    setColor(currentColor || "#ffffff");
  }, [currentColor]);

  return (
    <Modal show={isShowModal} onHide={onClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Выбор цвета</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-1">Результат:</p>
        <div style={{ backgroundColor: color, height: 60, width: "100%", marginBottom: 10, borderRadius: 8 }} />
        <HexColorPicker color={color} onChange={setColor} style={{ width: "100%" }} />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button onClick={onClose} variant="secondary">
          Отменить
        </Button>
        <Button onClick={() => onSave(color)} variant="primary">
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
