import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { Recommendation } from "../../../services/models/recommendation";
import RecommendationCard from "../../../pages/Admin/Recommendation/RecommendationCard";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";

interface Props {
  sectorId: string;
  recommendations: Recommendation[];
}

export const SectorRecommendations = ({ sectorId, recommendations }: Props) => {
  const navigate = useNavigate();

  return (
    <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
      {recommendations.map((recommendation) => (
        <Col key={recommendation.id}>
          <RecommendationCard
            recommendation={recommendation}
            onRecommendationClicked={() =>
              navigate(`/administrator/cabinet/recommendations/${sectorId}/${recommendation.id}`)
            }
          />
        </Col>
      ))}
    </Row>
  );
};
