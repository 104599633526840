import { MapSettingsColor } from "./../../../services/models/map_settings";
import { MapSectorInfo } from "../../../services/models/map_sector";

export const GRID_COLUMNS = 18;
export const CELL_SIZE = 64;
export const GAP = 2;

export const mapGrid = Array.from({ length: GRID_COLUMNS * 11 }, (_, index) => ({
  x: index % GRID_COLUMNS,
  y: Math.floor(index / GRID_COLUMNS),
}));

export const getElementAt = (sectors: MapSectorInfo[], x: number, y: number) =>
  sectors.find(({ coordinates }) => coordinates.x === x && coordinates.y === y);

export const getSectorColor = (colors: MapSettingsColor[], value: number) => {
  const color = colors.find((color) => value >= color.minValue && value <= color.maxValue);

  return color ? color.color : "#fff";
};
