import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { MapOfSubjectsWrapper } from "../../../components/AdminComponents/map-of-subjects/MapOfSubjectsWrapper";
import styles from "./AdministratorCabinetPage.module.css";

const AdministratorCabinetPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.navList}>
        <Link to="poll_templates">
          <Button className={styles.navItem}>Форма сбора данных</Button>
        </Link>
        <Link to="recommendations">
          <Button className={styles.navItem}>Рекомендации</Button>
        </Link>
        <Link to="statistics">
          <Button className={styles.navItem}>Статистика</Button>
        </Link>
        <Link to="sectors">
          <Button className={styles.navItem}>Субъекты РФ</Button>
        </Link>
        <Link to="roles">
          <Button className={styles.navItem}>Роли</Button>
        </Link>
        <Link to="sub_users">
          <Button className={styles.navItem}>Пользователи</Button>
        </Link>
      </div>

      <MapOfSubjectsWrapper />
    </div>
  );
};

export default AdministratorCabinetPage;
