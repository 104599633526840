import { useQuery } from "@tanstack/react-query";

import { fetchSectors } from "../../services/network/sector-api";

export function useFetchSectors() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/sectors"],
    queryFn: () => fetchSectors(),
  });

  return {
    sectorsData: data,
    sectorsError: error,
    sectorsIsLoading: isLoading,
  };
}
