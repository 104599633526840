import { useEffect, useState } from "react";
import { Sector } from "../../../services/models/sector";
import * as SectorApi from "../../../services/network/sector-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";
import { Link, useNavigate } from "react-router-dom";
import SectorCard from "./SectorCard";

const SectorsPageView = () => {
  const navigate = useNavigate();
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [sectorsLoading, setSectorsLoading] = useState(true);
  const [showSectorsLoadingError, setShowSectorsLoadingError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setShowSectorsLoadingError(false);
        setSectorsLoading(true);
        const sectorsData = await SectorApi.fetchSectors();
        setSectors(sectorsData);
      } catch (error) {
        console.error(error);
        setShowSectorsLoadingError(true);
      } finally {
        setSectorsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>

      <h1>Субъекты РФ</h1>

      {sectorsLoading && <Spinner animation="border" variant="primary" />}

      {showSectorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}

      <Row xs={1} md={1} xl={1} className={`g-4 mt-0 ${styles.projectsGrid}`}>
        {sectors
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((sector) => (
            <Col key={sector.id}>
              <Link to={sector.id} className="text-decoration-none">
                <SectorCard sector={sector} />
              </Link>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default SectorsPageView;
