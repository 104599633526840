import { useQuery } from "@tanstack/react-query";

import { fetchMapSettings } from "../../services/network/map_settings-api";

export function useFetchMapSettings() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/map_settings"],
    queryFn: () => fetchMapSettings(),
  });

  return {
    mapSettingsData: data,
    mapSettingsError: error,
    mapSettingsIsLoading: isLoading,
  };
}
