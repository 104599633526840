import { useEffect, useState } from "react";
import { Sector } from "../../../services/models/sector";
import * as SectorApi from "../../../services/network/sector-api";
import * as RecommendationApi from "../../../services/network/recommendation-api";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import stylesUtils from "../../../assets/styles/utils.module.css";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";
import { useNavigate } from "react-router-dom";
import RecommendationSectorCard from "./RecommendationSectorCard";
import { Recommendation } from "../../../services/models/recommendation";

const RecommendationSectorsPageView = () => {
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [sectorsLoading, setSectorsLoading] = useState(true);
  const [showSectorsLoadingError, setShowSectorsLoadingError] = useState(false);

  useEffect(() => {
    async function loadSectors() {
      try {
        setShowSectorsLoadingError(false);
        setSectorsLoading(true);
        const sectorsData = await SectorApi.fetchSectors();
        const recommendationsData = await RecommendationApi.fetchRecommendations();
        setRecommendations(recommendationsData);
        setSectors(sectorsData);
      } catch (error) {
        console.error(error);
        setShowSectorsLoadingError(true);
      } finally {
        setSectorsLoading(false);
      }
    }
    loadSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToRecommendations = (sector: Sector) => {
    let path = `${sector.id}`;
    navigate(path);
  };

  let navigate = useNavigate();

  const SectorsGrid = (
    <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
      {sectors
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((sector) => (
          <Col key={sector.id}>
            <RecommendationSectorCard
              sector={sector}
              recommendations={recommendations.filter((recommendation) => recommendation.sector === sector.id)}
              onSectorClicked={goToRecommendations}
            />
          </Col>
        ))}
    </Row>
  );

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      <h1>Субъекты РФ</h1>
      {sectorsLoading && <Spinner animation="border" variant="primary" />}
      {showSectorsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!sectorsLoading && !showSectorsLoadingError && (
        <>{sectors.length > 0 ? SectorsGrid : <p>Вы ещё не добавили субъекты</p>}</>
      )}
    </>
  );
};

export default RecommendationSectorsPageView;
