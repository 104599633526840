import { Button, Col, Row, Spinner } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import styles from "../../../assets/styles/ProjectsPage.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Poll as PollModel } from "../../../services/models/poll";
import * as PollApi from "../../../services/network/poll-api";
import * as SectorApi from "../../../services/network/sector-api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Poll from "../../../components/Poll";
import { Sector as SectorModel } from "../../../services/models/sector";
import DeleteWarningModal from "../../../components/DeleteWarningModal";

interface PollsPageViewProps {
  pollTemplateId: string;
  tagId: string;
}

const PollsPageView = ({ pollTemplateId, tagId }: PollsPageViewProps) => {
  const [polls, setPolls] = useState<PollModel[]>([]);
  const [sectors, setSectors] = useState<SectorModel[]>([]);
  const [pollsLoading, setPollsLoading] = useState(true);
  const [showPollsLoadingError, setShowPollsLoadingError] = useState(false);

  const [pollToDelete, setPollToDelete] = useState<PollModel | null>(null);

  useEffect(() => {
    async function loadPolls() {
      try {
        setShowPollsLoadingError(false);
        setPollsLoading(true);
        const pollsData = await PollApi.fetchPolls({ tag_id: tagId });
        const sectorsData = await SectorApi.fetchSectors();
        setPolls(pollsData);
        setSectors(sectorsData);
      } catch (error) {
        console.error(error);
        setShowPollsLoadingError(true);
      } finally {
        setPollsLoading(false);
      }
    }
    loadPolls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function deletePoll(poll: PollModel) {
    try {
      await PollApi.deletePoll(poll.id);
      setPolls(polls.filter((existingPoll) => existingPoll.id !== poll.id));
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  let navigate = useNavigate();

  const goToPollEdit = (poll: PollModel) => {
    let path = `${poll.id}`;
    navigate(path);
  };

  const goToPollCreate = () => {
    let path = `add`;
    navigate(path);
  };

  const goToFillPoll = (poll: PollModel) => {
    let path = `${poll.id}/fill`;
    navigate(path);
  };

  const goToPollResult = (poll: PollModel) => {
    let path = `${poll.id}/result`;
    navigate(path);
  };

  const pollsGrid = (
    <Row xs={1} md={1} xl={1} className={`g-4 ${styles.projectsGrid}`}>
      {polls.map((poll) => (
        <Col key={poll.id}>
          <Poll
            poll={poll}
            sector={sectors.find((sector) => {
              return sector.id === poll.sector;
            })}
            onEditPollClicked={goToPollEdit}
            onDeletePollClicked={setPollToDelete}
            onPollResultClicked={goToPollResult}
            onFillPollClicked={goToFillPoll}
            className={styles.project}
          />
        </Col>
      ))}
    </Row>
  );
  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      <h1>Формы сбора данных</h1>
      <Button className={`mb-4  ${stylesUtils.flexCenter}`} onClick={goToPollCreate}>
        <FaPlus />
        Добавить субъект РФ
      </Button>
      {pollsLoading && <Spinner animation="border" variant="primary" />}
      {showPollsLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!pollsLoading && !showPollsLoadingError && (
        <>{polls.length > 0 ? pollsGrid : <p>Формы сбора данных ещё не добавлены</p>}</>
      )}
      {pollToDelete && (
        <DeleteWarningModal
          onDismiss={() => setPollToDelete(null)}
          onDelete={() => {
            deletePoll(pollToDelete);
            setPollToDelete(null);
          }}
          text="Все данные будут удалены. Данное действие необратимо."
        />
      )}
    </>
  );
};

export default PollsPageView;
