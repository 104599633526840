import { Button, Dropdown, DropdownButton, Form, Modal, Spinner } from "react-bootstrap";
import { SubUser } from "../../../services/models/sub_user";
import { useForm } from "react-hook-form";
import * as SubUserApi from "../../../services/network/sub_user-api";
import * as SectorApi from "../../../services/network/sector-api";
import TextInputField from "../../../components/AdminComponents/form/TextInputField";
import { Role } from "../../../services/models/role";
import { useEffect, useState } from "react";
import * as RoleApi from "../../../services/network/role-api";
import { Sector } from "../../../services/models/sector";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";

interface AddEditSubUserDialogProps {
  subUserToEdit?: SubUser;
  onDismiss: () => void;
  onSubUserSaved: (subUser: SubUser) => void;
}

const AddEditSubUserDialog = ({ subUserToEdit, onDismiss, onSubUserSaved }: AddEditSubUserDialogProps) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [subUserRoles, setSubUserRoles] = useState<string[]>([]);
  const [rolesToShow, setRolesToShow] = useState<Role[]>([]);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [subUserSectors, setSubUserSectors] = useState<string[]>([]);
  const [sectorsToShow, setSectorsToShow] = useState<Sector[]>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showRolesLoadingError, setShowRolesLoadingError] = useState(false);

  const [rolesNameFilter, setRolesNameFilter] = useState("");
  const [sectorsNameFilter, setSectorsNameFilter] = useState("");

  const filterRoles = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const filteredArray = roles.filter((role) => {
        return role.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setRolesToShow(filteredArray);
    } else {
      setRolesToShow(roles);
    }

    setRolesNameFilter(keyword);
  };

  const filterSectors = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const filteredArray = sectors.filter((sector) => {
        return sector.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setSectorsToShow(filteredArray);
    } else {
      setSectorsToShow(sectors);
    }

    setSectorsNameFilter(keyword);
  };

  const handleRolesChange = (roleId: string) => {
    let updatedSubUserRoles = subUserRoles;
    if (updatedSubUserRoles.includes(roleId)) {
      updatedSubUserRoles = updatedSubUserRoles.filter((id) => id !== roleId);
    } else {
      updatedSubUserRoles.push(roleId);
    }
    setSubUserRoles([...updatedSubUserRoles]);
  };

  const handleSectorsChange = (sectorId: string) => {
    let updatedSubUserSectors = subUserSectors;
    if (updatedSubUserSectors.includes(sectorId)) {
      updatedSubUserSectors = updatedSubUserSectors.filter((id) => id !== sectorId);
    } else {
      updatedSubUserSectors.push(sectorId);
    }
    setSubUserSectors([...updatedSubUserSectors]);
  };

  useEffect(() => {
    async function loadRoles() {
      try {
        setShowRolesLoadingError(false);
        setDataLoading(true);
        const rolesData = await RoleApi.fetchRoles();
        const sectorsData = await SectorApi.fetchSectors();
        setSectors(sectorsData);
        setSectorsToShow(sectorsData);
        setRoles(rolesData);
        setRolesToShow(rolesData);
        if (subUserToEdit) {
          setSubUserRoles(subUserToEdit.roles);
          setSubUserSectors(subUserToEdit.sectors);
        }
      } catch (error) {
        console.error(error);
        setShowRolesLoadingError(true);
      } finally {
        setDataLoading(false);
      }
    }
    loadRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface SubUserFormInput {
    roles: string[];
    sectors: string[];
    full_name: string;
    email: string;
    password: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SubUserFormInput>({
    defaultValues: {
      roles: subUserToEdit?.roles || [],
      sectors: subUserToEdit?.sectors || [],
      full_name: subUserToEdit?.full_name || "",
      email: subUserToEdit?.email || "",
      password: "",
    },
  });

  async function onSubmit(input: SubUserFormInput) {
    try {
      input.roles = subUserRoles;
      input.sectors = subUserSectors;
      let subUserResponse: SubUser;
      if (subUserToEdit) {
        subUserResponse = await SubUserApi.updateSubUser(subUserToEdit.id, input);
      } else {
        subUserResponse = await SubUserApi.createSubUser(input);
      }
      onSubUserSaved(subUserResponse);
    } catch (error) {
      console.error(error);
      alert(error);
    }
  }

  return (
    <Modal show onHide={onDismiss}>
      <Modal.Header closeButton>
        <Modal.Title>{subUserToEdit ? "Редактировать пользователя" : "Добавить пользователя"}</Modal.Title>
      </Modal.Header>
      {dataLoading && <Spinner animation="border" variant="primary" />}
      {showRolesLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!dataLoading && !showRolesLoadingError && (
        <Modal.Body>
          <Form id="addEditSubUserForm" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-2" controlId={`roles`}>
              <Form.Label>Роли</Form.Label>
              <DropdownButton id={`roles-dropdown`} title="Выбрать роли" autoClose="outside">
                <input
                  type="search"
                  value={rolesNameFilter}
                  onChange={filterRoles}
                  className={styles.search}
                  placeholder="Поиск по названию"
                ></input>
                {rolesToShow.map((role) => (
                  <Dropdown.Item
                    key={role.id}
                    onClick={() => handleRolesChange(role.id)}
                    className={styles.subUserItem}
                  >
                    {role.name}
                    {subUserRoles.includes(role.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
            <Form.Group className="mb-2" controlId={`roles`}>
              <Form.Label>Субъекты РФ</Form.Label>
              <DropdownButton id={`sectors-dropdown`} title="Выбрать субъекты РФ" autoClose="outside">
                <input
                  type="search"
                  value={sectorsNameFilter}
                  onChange={filterSectors}
                  className={styles.search}
                  placeholder="Поиск по названию"
                ></input>
                {sectorsToShow.map((sector) => (
                  <Dropdown.Item
                    key={sector.id}
                    onClick={() => handleSectorsChange(sector.id)}
                    className={styles.subUserItem}
                  >
                    {sector.name}
                    {subUserSectors.includes(sector.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Form.Group>
            <TextInputField
              name="full_name"
              label="ФИО"
              type="text"
              placeholder="ФИО"
              register={register}
              registerOptions={{ required: "Required" }}
              error={errors.full_name}
            />
            <TextInputField
              name="email"
              label="Почта"
              type="text"
              placeholder="mail@example.ru"
              register={register}
              registerOptions={{ required: "Required" }}
              error={errors.email}
            />
            {subUserToEdit ? (
              <TextInputField
                name="password"
                label="Пароль"
                type="text"
                placeholder="Пароль"
                register={register}
                error={errors.password}
              />
            ) : (
              <TextInputField
                name="password"
                label="Пароль"
                type="text"
                placeholder="Пароль"
                register={register}
                registerOptions={{ required: "Required" }}
                error={errors.password}
              />
            )}
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button type="submit" form="addEditSubUserForm" disabled={isSubmitting}>
          Сохранить
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditSubUserDialog;
