import { FormEvent, useEffect, useState } from "react";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { useNavigate } from "react-router-dom";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as RoleApi from "../../../services/network/role-api";
import { Button, Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { Role } from "../../../services/models/role";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";

interface CreateUpdatePollTemplatePageViewProps {
  pollTemplateId?: string;
  createNew: boolean;
}

const CreateUpdatePollTemplatePageView = ({ pollTemplateId, createNew }: CreateUpdatePollTemplatePageViewProps) => {
  const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>({
    id: "",
    allowed_roles: [],
    title: "",
    description: "",
    modules: [
      {
        id: "",
        allowed_roles: [],
        title: "",
        description: "",
        values: [
          {
            id: "",
            allowed_roles: [],
            title: "",
            value: "",
          },
        ],
      },
    ],
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [rolesToShow, setRolesToShow] = useState<Role[]>([]);
  const [pollTemplateLoading, setPollTemplateLoading] = useState(true);
  const [showPollTemplateLoadingError, setShowPollTemplateLoadingError] = useState(false);

  const [rolesNameFilter, setRolesNameFilter] = useState("");

  useEffect(() => {
    async function loadPollTemplate() {
      if (pollTemplateId) {
        try {
          setShowPollTemplateLoadingError(false);
          setPollTemplateLoading(true);
          const pollTemplateData = await PollTemplateApi.fetchPollTemplate({ pollTemplateId: pollTemplateId });
          const rolesData = await RoleApi.fetchRoles();
          setRoles(rolesData);
          setRolesToShow(rolesData);
          setPollTemplate(pollTemplateData);
        } catch (error) {
          console.error(error);
          setShowPollTemplateLoadingError(true);
        } finally {
          setPollTemplateLoading(false);
        }
      } else {
        try {
          setShowPollTemplateLoadingError(false);
          setPollTemplateLoading(true);
          const rolesData = await RoleApi.fetchRoles();
          setRoles(rolesData);
          setRolesToShow(rolesData);
        } catch (error) {
          console.error(error);
          setShowPollTemplateLoadingError(true);
        } finally {
          setPollTemplateLoading(false);
        }
      }
    }
    loadPollTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filter = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const filteredArray = roles.filter((role) => {
        return role.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setRolesToShow(filteredArray);
    } else {
      setRolesToShow(roles);
    }

    setRolesNameFilter(keyword);
  };

  let navigate = useNavigate();

  const handlePollTemplateChange = (field: keyof PollTemplateModel, value: string) => {
    setPollTemplate({ ...pollTemplate, [field]: value });
  };

  const handleModuleChange = (index: number, field: keyof PollTemplateModel["modules"][0], value: string) => {
    const updatedModules = pollTemplate.modules;
    const allowedRoles = pollTemplate.allowed_roles;
    if (field !== "values" && field !== "allowed_roles") {
      updatedModules[index][field] = value;
    }
    if (field === "allowed_roles") {
      if (!updatedModules[index][field]) {
        updatedModules[index][field] = [];
      }
      if (!updatedModules[index][field].includes(value)) {
        updatedModules[index][field].push(value);
        for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
          if (!updatedModules[index].values[valueIndex][field].includes(value)) {
            updatedModules[index].values[valueIndex][field].push(value);
          }
        }
        if (!pollTemplate[field].includes(value)) {
          pollTemplate[field].push(value);
        }
      } else {
        updatedModules[index][field] = updatedModules[index][field].filter((id) => id !== value);
        for (let valueIndex = 0; valueIndex < updatedModules[index].values.length; valueIndex++) {
          updatedModules[index].values[valueIndex][field] = updatedModules[index].values[valueIndex][field].filter(
            (id) => id !== value
          );
        }
        let inOtherModules = false;
        for (let i = 0; i < updatedModules.length; i++) {
          if (updatedModules[i][field].includes(value)) {
            inOtherModules = true;
            break;
          }
        }
        if (!inOtherModules) {
          pollTemplate[field] = allowedRoles.filter((id) => id !== value);
        }
      }
    }
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  const handleAddModule = () => {
    const updatedModules = pollTemplate.modules;
    updatedModules.push({
      id: "",
      allowed_roles: [],
      title: "",
      description: "",
      values: [
        {
          id: "",
          allowed_roles: [],
          title: "",
          value: "",
        },
      ],
    });
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  const handleRemoveModule = (index: number) => {
    const updatedModules = pollTemplate.modules;
    updatedModules.splice(index, 1);
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  const handleValueChange = (
    moduleIndex: number,
    valueIndex: number,
    field: keyof PollTemplateModel["modules"][0]["values"][0],
    value: string
  ) => {
    const updatedModules = pollTemplate.modules;
    if (field === "title" && typeof value === "string") {
      updatedModules[moduleIndex].values[valueIndex][field] = value;
    } else if (field === "value" && typeof value === "number") {
      updatedModules[moduleIndex].values[valueIndex][field] = value;
    } else if (field === "allowed_roles" && typeof value === "string") {
      if (!updatedModules[moduleIndex].values[valueIndex][field]) {
        updatedModules[moduleIndex].values[valueIndex][field] = [];
      }
      if (!updatedModules[moduleIndex].values[valueIndex][field].includes(value)) {
        updatedModules[moduleIndex].values[valueIndex][field].push(value);
        if (!updatedModules[moduleIndex][field].includes(value)) {
          updatedModules[moduleIndex][field].push(value);
        }
        if (!pollTemplate[field].includes(value)) {
          pollTemplate[field].push(value);
        }
      } else {
        updatedModules[moduleIndex].values[valueIndex][field] = updatedModules[moduleIndex].values[valueIndex][
          field
        ].filter((id) => id !== value);
        let inOtherValues = false;
        for (let i = 0; i < updatedModules[moduleIndex].values.length; i++) {
          if (updatedModules[moduleIndex].values[i][field].includes(value)) {
            inOtherValues = true;
            break;
          }
        }
        if (!inOtherValues) {
          updatedModules[moduleIndex][field] = updatedModules[moduleIndex][field].filter((id) => id !== value);
        }
        let inOtherModules = false;
        for (let i = 0; i < updatedModules.length; i++) {
          if (updatedModules[i][field].includes(value)) {
            inOtherModules = true;
            break;
          }
        }
        if (!inOtherModules) {
          pollTemplate[field] = pollTemplate[field].filter((id) => id !== value);
        }
      }
    }
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  const handleAddValue = (moduleIndex: number) => {
    const updatedModules = pollTemplate.modules;
    updatedModules[moduleIndex].values.push({
      id: "",
      allowed_roles: [...updatedModules[moduleIndex].allowed_roles],
      title: "",
      value: "",
    });
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  const handleRemoveValue = (moduleIndex: number, valueIndex: number) => {
    const updatedModules = pollTemplate.modules;
    updatedModules[moduleIndex].values.splice(valueIndex, 1);
    setPollTemplate({ ...pollTemplate, modules: updatedModules });
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      if (!createNew && pollTemplateId) {
        let pollResponse: PollTemplateModel;
        pollResponse = await PollTemplateApi.updatePollTemplate(pollTemplateId, pollTemplate);
        setPollTemplate(pollResponse);
      } else if (createNew) {
        let pollResponse: PollTemplateModel;
        pollResponse = await PollTemplateApi.createPollTemplate(pollTemplate);
        setPollTemplate(pollResponse);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      navigate(-1);
    }
  }

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      {pollTemplateLoading && <Spinner animation="border" variant="primary" />}
      {showPollTemplateLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!pollTemplateLoading && !showPollTemplateLoadingError && (
        <Form onSubmit={handleSubmit}>
          {createNew ? <h1>Создание формы сбора данных</h1> : <h1>Редактирование формы сбора данных</h1>}
          <Form.Group className="mb-2" controlId={`poll-title`}>
            <Form.Label>Название</Form.Label>
            <Form.Control
              type="text"
              value={pollTemplate.title}
              onChange={(e) => handlePollTemplateChange("title", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId={`poll-description`}>
            <Form.Label>Описание</Form.Label>
            <Form.Control
              type="textarea"
              value={pollTemplate.description}
              onChange={(e) => handlePollTemplateChange("description", e.target.value)}
            />
          </Form.Group>
          {pollTemplate.modules.map((module, moduleIndex) => (
            <Card key={moduleIndex} className="mb-3">
              <Card.Header>Модуль {moduleIndex + 1}</Card.Header>
              <Card.Body>
                <Form.Group className="mb-2" controlId={`module-roles-${moduleIndex}`}>
                  <Form.Label>Разрешённые роли</Form.Label>
                  <DropdownButton id={`allowed-roles-dropdown-${moduleIndex}`} title="Выбрать роли" autoClose="outside">
                    <input
                      type="search"
                      value={rolesNameFilter}
                      onChange={filter}
                      className={styles.search}
                      placeholder="Поиск по названию"
                    ></input>
                    {rolesToShow.map((role) => (
                      <Dropdown.Item
                        key={role.id}
                        onClick={() => handleModuleChange(moduleIndex, "allowed_roles", role.id)}
                        className={styles.subUserItem}
                      >
                        {role.name}
                        {module.allowed_roles?.includes(role.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Form.Group>
                <Form.Group className="mb-2" controlId={`module-name-${moduleIndex}`}>
                  <Form.Label>Название</Form.Label>
                  <Form.Control
                    type="text"
                    value={module.title}
                    onChange={(e) => handleModuleChange(moduleIndex, "title", e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId={`module-description-${moduleIndex}`}>
                  <Form.Label>Описание</Form.Label>
                  <Form.Control
                    type="textarea"
                    value={module.description}
                    onChange={(e) => handleModuleChange(moduleIndex, "description", e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId={`module-values-${moduleIndex}`}>
                  <h3>Значения</h3>
                  {module.values.map((value, valueIndex) => (
                    <div key={valueIndex}>
                      <Form.Group className={`mb-3`} controlId={`value-name-${moduleIndex}-${valueIndex}`}>
                        <Form.Label>Название</Form.Label>
                        <div className={styles.value}>
                          <Form.Control
                            type="text"
                            value={value.title}
                            onChange={(e) => handleValueChange(moduleIndex, valueIndex, "title", e.target.value)}
                          />
                          <DropdownButton
                            id={`allowed-roles-dropdown-${moduleIndex}-${valueIndex}`}
                            title="Роли"
                            autoClose="outside"
                            className={`ms-2`}
                          >
                            <input
                              type="search"
                              value={rolesNameFilter}
                              onChange={filter}
                              className={styles.search}
                              placeholder="Поиск по названию"
                            ></input>
                            {rolesToShow.map((role) => (
                              <Dropdown.Item
                                key={role.id}
                                onClick={() => handleValueChange(moduleIndex, valueIndex, "allowed_roles", role.id)}
                                className={styles.subUserItem}
                              >
                                {role.name}
                                {value.allowed_roles?.includes(role.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                              </Dropdown.Item>
                            ))}
                          </DropdownButton>
                          <Button
                            className="ms-2"
                            variant="danger"
                            onClick={() => handleRemoveValue(moduleIndex, valueIndex)}
                          >
                            Удалить
                          </Button>
                        </div>
                      </Form.Group>
                    </div>
                  ))}
                  <div className={stylesUtils.flexCenter}>
                    <Button onClick={() => handleAddValue(moduleIndex)}>Добавить значение</Button>
                  </div>
                </Form.Group>
              </Card.Body>
              {pollTemplate.modules.length > 1 && (
                <Card.Footer>
                  <Button variant="danger" onClick={() => handleRemoveModule(moduleIndex)}>
                    Удалить модуль
                  </Button>
                </Card.Footer>
              )}
            </Card>
          ))}
          <div className={`mb-3 ${stylesUtils.flexCenterSpaceAround}`}>
            <Button onClick={handleAddModule}>Добавить модуль</Button>
          </div>
          <div className={stylesUtils.flexCenter}>
            <Button type="submit">Сохранить</Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CreateUpdatePollTemplatePageView;
