import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import stylesUtils from "../../assets/styles/utils.module.css";

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
      <Button className={`mb-1`} onClick={() => navigate(-1)}>
        Назад
      </Button>
    </div>
  );
};
