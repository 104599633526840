import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";

import { SectorPageView } from "./SectorPageView";
import { SectorPollsPageView } from "./SectorPollsPageView";
import { SectorRecommendationsPageView } from "./SectorRecommendationsPageView";
import { SectorUsersPageView } from "./SectorUsersPageView";
import NotFoundPage from "../../NotFound/NotFoundPage";
import styles from "../../../assets/styles/ProjectPage.module.css";

interface Props {
  type: "main" | "polls" | "recommendations" | "users";
}

export const SectorPage = ({ type }: Props) => {
  const { sectorId } = useParams();

  return (
    <Container className={styles.projectPage}>
      {!sectorId ? (
        <NotFoundPage />
      ) : (
        <>
          {type === "main" && <SectorPageView sectorId={sectorId} />}
          {type === "polls" && <SectorPollsPageView sectorId={sectorId} />}
          {type === "recommendations" && <SectorRecommendationsPageView sectorId={sectorId} />}
          {type === "users" && <SectorUsersPageView sectorId={sectorId} />}
        </>
      )}
    </Container>
  );
};
