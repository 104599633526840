import { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { useFetchMapSettings, useUpdateMapSettings } from "../../../hooks/useQuery";
import { BackButton } from "../../../components/shared/BackButton";
import { MapSettingsSelectedIndicator } from "../../../components/AdminComponents/map-settings/MapSettingsSelectedIndicator";
import { MapSettingsSelectingIndicator } from "../../../components/AdminComponents/map-settings/MapSettingsSelectingIndicator";
import { MapSettingsColor, MapSettingsCreate, MapSettingsIndicatorUpdate } from "../../../services/models/map_settings";
import { MapSettingsColorBlock } from "../../../components/AdminComponents/map-settings/MapSettingsColorBlock";

export const MapSettingsPageView = () => {
  const { mapSettingsData, mapSettingsError, mapSettingsIsLoading } = useFetchMapSettings();
  const { updateMapSettings } = useUpdateMapSettings();
  const [isSelectindIndicator, setIsSelectindIndicator] = useState(false);
  const [colors, setColors] = useState<MapSettingsColor[]>([]);

  useEffect(() => {
    if (mapSettingsData?.colors?.length) {
      setColors(mapSettingsData.colors);
    }
  }, [mapSettingsData]);

  const handleUpdateIndicator = (updatedValue: MapSettingsIndicatorUpdate) => {
    updateMapSettings({
      ...updatedValue,
      colors,
    });
    setIsSelectindIndicator(false);
  };

  const handleUpdateColors = (updatedColors: MapSettingsColor[]) => {
    const data: MapSettingsCreate = { tagId: "", moduleId: "", valueId: "", colors: updatedColors };

    if (mapSettingsData) {
      data.tagId = mapSettingsData.tag?.id || "";
      data.moduleId = mapSettingsData.module?.id || "";
      data.valueId = mapSettingsData.value?.id || "";
    }

    updateMapSettings(data);
    setIsSelectindIndicator(false);
  };

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      <BackButton />

      {mapSettingsIsLoading && <Spinner animation="border" variant="primary" />}
      {mapSettingsError && <p>Something went wrong. Please refresh the page.</p>}

      {!mapSettingsIsLoading && !mapSettingsError && (
        <>
          <h2>Показатель</h2>

          {isSelectindIndicator && (
            <MapSettingsSelectingIndicator
              onUpdateMapSettings={handleUpdateIndicator}
              onClose={() => setIsSelectindIndicator(false)}
            />
          )}

          {!isSelectindIndicator &&
            (mapSettingsData ? (
              <>
                <MapSettingsSelectedIndicator
                  mapSettings={mapSettingsData}
                  onSelectIndicator={() => setIsSelectindIndicator(true)}
                />

                <h3 className="mt-5">Настройка цветов показателей</h3>
                <MapSettingsColorBlock colors={colors} onSave={(value) => handleUpdateColors(value)} />
              </>
            ) : (
              <div className="mt-3 text-center">
                <Button onClick={() => setIsSelectindIndicator(true)}>Выбрать показатель</Button>
              </div>
            ))}
        </>
      )}
    </div>
  );
};
