import { Card } from "react-bootstrap";
import styles from "../../../assets/styles/Project.module.css";
import styleUtils from "../../../assets/styles/utils.module.css";
import { Recommendation } from "../../../services/models/recommendation";
import { MdDelete, MdEdit } from "react-icons/md";

interface SectorCardProps {
  recommendation: Recommendation;
  onRecommendationClicked: (recommendation: Recommendation) => void;
  onUpdateRecommendationClicked?: (recommendation: Recommendation) => void;
  onDeleteRecommendationClicked?: (recommendation: Recommendation) => void;
  className?: string;
}

const RecommendationCard = ({
  recommendation,
  onRecommendationClicked,
  onUpdateRecommendationClicked,
  onDeleteRecommendationClicked,
  className,
}: SectorCardProps) => {
  const { name } = recommendation;
  return (
    <Card
      className={`${styles.projectCard} ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onRecommendationClicked(recommendation);
      }}
    >
      <Card.Body className={styles.cardBody}>
        <Card.Title className={styleUtils.flexCenterSpaceBetween}>
          {name}
          <div>
            {onUpdateRecommendationClicked && (
              <MdEdit
                className="ms-2 text-muted ms-rights"
                onClick={(e) => {
                  onUpdateRecommendationClicked(recommendation);
                  e.stopPropagation();
                }}
              />
            )}

            {onDeleteRecommendationClicked && (
              <MdDelete
                className="ms-2 text-muted ms-right"
                onClick={(e) => {
                  onDeleteRecommendationClicked(recommendation);
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        </Card.Title>
      </Card.Body>
    </Card>
  );
};

export default RecommendationCard;
