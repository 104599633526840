export function calculateModuleRating(module: { values: { value: string }[] }) {
  const filteredValues = module.values.filter((value) => value.value !== "-" && value.value !== "");

  if (filteredValues.length === 0) return null;

  return filteredValues.reduce((sum, value) => sum + Number(value.value), 0);
}

export function calculatePollRating(poll: { modules: { values: { value: string }[] }[] }) {
  const filteredValues = poll.modules
    .map((module) => module.values.map((value) => value.value))
    .flat()
    .filter((value) => value !== "-" && value !== "");

  if (filteredValues.length === 0) return null;

  return filteredValues.reduce((sum, value) => sum + Number(value), 0);
}
