import { useQuery } from "@tanstack/react-query";

import { fetchMapSectorsInfo } from "../../services/network/map_sector-api";

export function useFetchMapSectorsInfo() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/sectors/map-info"],
    queryFn: () => fetchMapSectorsInfo(),
  });

  return {
    mapSectorsInfoData: data,
    mapSectorsInfoError: error,
    mapSectorsInfoIsLoading: isLoading,
  };
}
