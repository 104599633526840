import { useQuery } from "@tanstack/react-query";

import { fetchSectorInfo } from "../../services/network/sector-api";

export function useFetchSectorInfo(sectorId: string) {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/sector/info", sectorId],
    queryFn: () => fetchSectorInfo(sectorId),
  });

  return {
    sectorInfoData: data,
    sectorInfoError: error,
    sectorInfoIsLoading: isLoading,
  };
}
