import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useFetchSectorInfo } from "../../../hooks/useQuery";
import stylesUtils from "../../../assets/styles/utils.module.css";

interface SectorsPageViewProps {
  sectorId: string;
}

const classNameLinkBtn = `mb-4 ${stylesUtils.flexSizeButton} ${stylesUtils.flexCenter}`;

export const SectorPageView = ({ sectorId }: SectorsPageViewProps) => {
  const navigate = useNavigate();
  const { sectorInfoData, sectorInfoError, sectorInfoIsLoading } = useFetchSectorInfo(sectorId);

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>

      {sectorInfoIsLoading && <Spinner animation="border" variant="primary" />}
      {sectorInfoError && <p>Something went wrong. Please refresh the page.</p>}

      {sectorInfoData && (
        <>
          <h2>{sectorInfoData.sector.name}</h2>

          <div className="mt-2">
            <Link to="polls">
              <Button className={classNameLinkBtn}>Формы сбора данных ({sectorInfoData.polls.length})</Button>
            </Link>
            <Link to="recommendations">
              <Button className={classNameLinkBtn}>Рекомендации ({sectorInfoData.recommendations.length})</Button>
            </Link>
            <Link to="users">
              <Button className={classNameLinkBtn}>Пользователи ({sectorInfoData.users.length})</Button>
            </Link>
          </div>
        </>
      )}
    </>
  );
};
