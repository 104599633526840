import { useQuery } from "@tanstack/react-query";

import { fetchPollTemplates } from "../../services/network/poll_template-api";

export function useFetchPollTemplates() {
  const { data, error, isLoading } = useQuery({
    queryKey: ["/poll_template"],
    queryFn: () => fetchPollTemplates(),
  });

  return {
    pollTemplatesData: data,
    pollTemplatesError: error,
    pollTemplatesIsLoading: isLoading,
  };
}
