import { Container } from "react-bootstrap";

import SectorsPageView from "./SectorsPageView";
import styles from "../../../assets/styles/ProjectPage.module.css";

export const SectorsPage = () => {
  return (
    <Container className={styles.projectPage}>
      <SectorsPageView />
    </Container>
  );
};
