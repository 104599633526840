import { PollTemplate } from "../services/models/poll_template";
import { SubUser } from "../services/models/sub_user";
import * as PollApi from "../services/network/poll-api";

interface createPollsProps {
  sectorIdsToUse: string[];
  pollTemplate: PollTemplate;
  subUsers: SubUser[];
  pollTagId: string;
}

export default function createPolls({ sectorIdsToUse, pollTemplate, subUsers, pollTagId }: createPollsProps) {
  sectorIdsToUse.forEach(async (sectorId) => {
    let poll: PollApi.PollInput = {
      sector: sectorId,
      tag: pollTagId,
      finished_sub_users: [],
      allowed_sub_users: [],
      title: pollTemplate.title,
      description: pollTemplate.description,
      modules: [],
    };
    for (let moduleIndex = 0; moduleIndex < pollTemplate.modules.length; moduleIndex++) {
      const module = pollTemplate.modules[moduleIndex];
      poll.modules.push({
        id: module.id,
        allowed_sub_users: [],
        title: module.title,
        description: module.description,
        values: [],
      });
      for (let valueIndex = 0; valueIndex < module.values.length; valueIndex++) {
        const value = module.values[valueIndex];
        const allowedSubUsers = subUsers
          .filter((subUser) => {
            return (
              subUser.sectors.includes(sectorId) && value.allowed_roles.some((role) => subUser.roles.includes(role))
            );
          })
          .map((subUser) => subUser.id);
        poll.modules[moduleIndex].values.push({
          id: value.id,
          allowed_sub_users: allowedSubUsers,
          title: value.title,
          value: "",
        });
        for (let subUserIndex = 0; subUserIndex < allowedSubUsers.length; subUserIndex++) {
          if (!(allowedSubUsers[subUserIndex] in poll.modules[moduleIndex].allowed_sub_users)) {
            poll.modules[moduleIndex].allowed_sub_users.push(allowedSubUsers[subUserIndex]);
          }
          if (!(allowedSubUsers[subUserIndex] in poll.allowed_sub_users)) {
            poll.allowed_sub_users.push(allowedSubUsers[subUserIndex]);
          }
        }
      }
    }
    await PollApi.createPoll(poll);
  });
}
