import { Spinner } from "react-bootstrap";

import { useFetchMapSectorsInfo } from "../../../hooks/useQuery";
import { MapOfSubjectsView } from "./MapOfSubjectsView";

export const MapOfSubjectsWrapper = () => {
  const { mapSectorsInfoData, mapSectorsInfoIsLoading, mapSectorsInfoError } = useFetchMapSectorsInfo();

  return (
    <>
      {mapSectorsInfoIsLoading && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {mapSectorsInfoError && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <p>Something went wrong. Please refresh the page.</p>
        </div>
      )}

      {mapSectorsInfoData && <MapOfSubjectsView sectors={mapSectorsInfoData} />}
    </>
  );
};
