import { Spinner } from "react-bootstrap";

import { useFetchSectorInfo } from "../../../hooks/useQuery";
import { SectorRecommendations } from "../../../components/AdminComponents/sectors/SectorRecommendations";
import { BackButton } from "../../../components/shared/BackButton";

interface Props {
  sectorId: string;
}

export const SectorRecommendationsPageView = ({ sectorId }: Props) => {
  const { sectorInfoData, sectorInfoError, sectorInfoIsLoading } = useFetchSectorInfo(sectorId);

  return (
    <>
      <BackButton />

      {sectorInfoIsLoading && <Spinner animation="border" variant="primary" />}
      {sectorInfoError && <p>Something went wrong. Please refresh the page.</p>}

      {sectorInfoData && (
        <>
          <h2>{sectorInfoData.sector.name}</h2>

          <h4 className="mt-2 mb-3">Рекомендации</h4>

          {sectorInfoData.recommendations.length ? (
            <SectorRecommendations sectorId={sectorId} recommendations={sectorInfoData.recommendations} />
          ) : (
            <p>Рекомендации не найдены</p>
          )}
        </>
      )}
    </>
  );
};
