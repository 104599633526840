import { Spinner, Row, Col } from "react-bootstrap";

import { useFetchSectorInfo, useFetchSectors, useFetchRoles } from "../../../hooks/useQuery";
import { BackButton } from "../../../components/shared/BackButton";
import SubUserCard from "../../Admin/SubUser/SubUserCard";
import styles from "../../../assets/styles/TeacherCuratorsGrid.module.css";

interface Props {
  sectorId: string;
}

export const SectorUsersPageView = ({ sectorId }: Props) => {
  const { sectorInfoData, sectorInfoError, sectorInfoIsLoading } = useFetchSectorInfo(sectorId);
  const { sectorsData, sectorsError, sectorsIsLoading } = useFetchSectors();
  const { rolesData, rolesError, rolesIsLoading } = useFetchRoles();

  return (
    <>
      <BackButton />

      {(sectorInfoIsLoading || sectorsIsLoading || rolesIsLoading) && <Spinner animation="border" variant="primary" />}
      {(sectorInfoError || sectorsError || rolesError) && <p>Something went wrong. Please refresh the page.</p>}

      {sectorInfoData && sectorsData && rolesData && (
        <>
          <h2>{sectorInfoData.sector.name}</h2>
          <h4 className="mt-2 mb-3">Пользователи</h4>

          {sectorInfoData.users.length ? (
            <Row xs={1} md={1} xl={1} className={`g-4 ${styles.subUsersGrid}`}>
              {sectorInfoData.users.map((user) => (
                <Col key={user.id}>
                  <SubUserCard
                    subUser={user}
                    roles={rolesData.filter((role) => user.roles.includes(role.id))}
                    sectors={sectorsData.filter((sector) => user.sectors.includes(sector.id))}
                    isEditable={false}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <p>Пользователи не найдены</p>
          )}
        </>
      )}
    </>
  );
};
