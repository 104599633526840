import { Spinner } from "react-bootstrap";

import { useFetchSectorInfo } from "../../../hooks/useQuery";
import { SectorPolls } from "../../../components/AdminComponents/sectors/SectorPolls";
import { BackButton } from "../../../components/shared/BackButton";

interface Props {
  sectorId: string;
}

export const SectorPollsPageView = ({ sectorId }: Props) => {
  const { sectorInfoData, sectorInfoError, sectorInfoIsLoading } = useFetchSectorInfo(sectorId);

  return (
    <>
      <BackButton />

      {sectorInfoIsLoading && <Spinner animation="border" variant="primary" />}
      {sectorInfoError && <p>Something went wrong. Please refresh the page.</p>}

      {sectorInfoData && (
        <>
          <h2>{sectorInfoData.sector.name}</h2>

          <h4 className="mt-2 mb-3">Формы сбора данных</h4>

          {sectorInfoData.polls.length ? (
            <SectorPolls
              sector={sectorInfoData.sector}
              polls={sectorInfoData.polls}
              pollTags={sectorInfoData.pollTags}
            />
          ) : (
            <p>Формы сбора данных не найдены</p>
          )}
        </>
      )}
    </>
  );
};
