import { FormEvent, useEffect, useState } from "react";
import { PollTemplate as PollTemplateModel } from "../../../services/models/poll_template";
import { PollTag as PollTagModel } from "../../../services/models/poll_tag";
import { Sector as SectorModel } from "../../../services/models/sector";
import { SubUser as SubUserModel } from "../../../services/models/sub_user";
import { useNavigate } from "react-router-dom";
import * as PollTemplateApi from "../../../services/network/poll_template-api";
import * as PollTagApi from "../../../services/network/poll_tag-api";
import * as SectorApi from "../../../services/network/sector-api";
import * as SubUserApi from "../../../services/network/sub_user-api";
import { Button, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap";
import styles from "../../../assets/styles/CreateUpdatePoll.module.css";
import stylesUtils from "../../../assets/styles/utils.module.css";
import { FaRegCheckCircle, FaRegCircle } from "react-icons/fa";
import createPolls from "../../../Utils/createPolls";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface CreateUpdatePollTagPageViewProps {
  pollTemplateId: string;
  pollTagId?: string;
  createNew: boolean;
}

const CreateUpdatePollTagPageView = ({ pollTemplateId, pollTagId, createNew }: CreateUpdatePollTagPageViewProps) => {
  interface PollTagInput {
    poll_template: string;
    date: string;
  }
  const [pollTag, setPollTag] = useState<PollTagInput>({
    poll_template: pollTemplateId,
    date: new Date().toDateString(),
  });
  const [pollTemplate, setPollTemplate] = useState<PollTemplateModel>();
  const [subUsers, setSubUsers] = useState<SubUserModel[]>([]);
  const [sectors, setSectors] = useState<SectorModel[]>([]);
  const [sectorsToShow, setSectorsToShow] = useState<SectorModel[]>([]);
  const [sectorIdsToUse, setSectorIdsToUse] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [showDataLoadingError, setShowDataLoadingError] = useState(false);

  const [sectorNameFilter, setSectorsNameFilter] = useState("");

  useEffect(() => {
    async function loadData() {
      if (!createNew && pollTagId) {
        try {
          setShowDataLoadingError(false);
          setDataLoading(true);
          const pollTagData = await PollTagApi.fetchPollTag(pollTagId);
          setPollTag(pollTagData);
        } catch (error) {
          console.error(error);
          setShowDataLoadingError(true);
        } finally {
          setDataLoading(false);
        }
      } else {
        try {
          setShowDataLoadingError(false);
          setDataLoading(true);
          const subUsersData = await SubUserApi.getSubUsers();
          const sectorsData = await SectorApi.fetchSectors();
          const pollTemplateData = await PollTemplateApi.fetchPollTemplate({ pollTemplateId });
          if (pollTagId) {
            const pollTagData = await PollTagApi.fetchPollTag(pollTagId);
            setPollTag(pollTagData);
          }
          setSubUsers(subUsersData);
          setSectors(sectorsData);
          setSectorsToShow(sectorsData);
          setPollTemplate(pollTemplateData);
        } catch (error) {
          console.error(error);
          setShowDataLoadingError(true);
        } finally {
          setDataLoading(false);
        }
      }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSectors = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const filteredArray = sectors.filter((sector) => {
        return sector.name.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setSectorsToShow(filteredArray);
    } else {
      setSectorsToShow(sectors);
    }

    setSectorsNameFilter(keyword);
  };

  let navigate = useNavigate();

  const handlePollTagDateChange = (newDate: Date | null) => {
    if (newDate === null) {
      const updatedPollTag = pollTag;
      updatedPollTag.date = new Date().toDateString();
      setPollTag({ ...updatedPollTag });
    } else {
      console.log(newDate);
      const updatedPollTag = pollTag;
      updatedPollTag.date = newDate.toDateString();
      setPollTag({ ...updatedPollTag });
    }
  };

  const handleSectorIdsToUseChange = (sectorId: string) => {
    let sectorIds = sectorIdsToUse;
    const index = sectorIds.indexOf(sectorId, 0);
    if (index > -1) {
      sectorIds.splice(index, 1);
    } else {
      sectorIds.push(sectorId);
    }
    setSectorsToShow([...sectorsToShow]);
    setSectorIdsToUse(sectorIds);
  };

  const handleSectorIdsToUseAllAdd = (sectors: SectorModel[]) => {
    let sectorIds = sectorIdsToUse;
    let deleteSectors = true;
    for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++) {
      const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
      if (!(index > -1)) {
        deleteSectors = false;
        sectorIds.push(sectors[sectorIndex].id);
      }
    }
    if (deleteSectors) {
      for (let sectorIndex = 0; sectorIndex < sectors.length; sectorIndex++) {
        const index = sectorIds.indexOf(sectors[sectorIndex].id, 0);
        if (index > -1) {
          sectorIds.splice(index, 1);
        }
      }
    }
    setSectorsToShow([...sectorsToShow]);
    setSectorIdsToUse(sectorIds);
  };

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    try {
      if (!createNew && pollTagId) {
        let pollTagResponse: PollTagModel;
        pollTagResponse = await PollTagApi.updatePollTag(pollTagId, pollTag);
        setPollTag(pollTagResponse);
      } else if (createNew && pollTemplate) {
        let pollTagResponse: PollTagModel;
        pollTagResponse = await PollTagApi.createPollTag(pollTag);
        createPolls({ sectorIdsToUse, pollTemplate, subUsers, pollTagId: pollTagResponse.id });
        setPollTag(pollTagResponse);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    } finally {
      navigate(-1);
    }
  }

  const renderMonthContent = (month: number, shortMonth: string, longMonth: string, day: Date) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <>
      <div className={`${stylesUtils.width100} ${stylesUtils.flexLeft}`}>
        <Button className={`mb-1`} onClick={() => navigate(-1)}>
          Назад
        </Button>
      </div>
      {dataLoading && <Spinner animation="border" variant="primary" />}
      {showDataLoadingError && <p>Something went wrong. Please refresh the page.</p>}
      {!dataLoading && !showDataLoadingError && (
        <Form onSubmit={handleSubmit}>
          {createNew ? <h1>Создание формы сбора данных</h1> : <h1>Редактирование формы сбора данных</h1>}
          <Form.Group className="mb-2" controlId={`poll-title`}>
            <Form.Label>Дата</Form.Label>
            <DatePicker
              selected={new Date(pollTag.date)}
              renderMonthContent={renderMonthContent}
              showMonthYearPicker
              onChange={(date) => {
                handlePollTagDateChange(date);
              }}
              dateFormat="MM/yyyy"
            />
          </Form.Group>
          {createNew && (
            <>
              <Form.Group className="mb-2" controlId={`sectors`}>
                <Form.Label>Субъекты РФ</Form.Label>
                <DropdownButton id={`sectors-dropdown`} title="Выбрать субъекты" autoClose="outside">
                  <input
                    type="search"
                    value={sectorNameFilter}
                    onChange={filterSectors}
                    className={styles.search}
                    placeholder="Поиск по названию"
                  ></input>
                  <Button onClick={() => handleSectorIdsToUseAllAdd(sectorsToShow)}>Выбрать все</Button>
                  {sectorsToShow.map((sector) => (
                    <Dropdown.Item
                      key={sector.id}
                      onClick={() => handleSectorIdsToUseChange(sector.id)}
                      className={styles.subUserItem}
                    >
                      {sector.name}
                      {sectorIdsToUse.includes(sector.id) ? <FaRegCheckCircle /> : <FaRegCircle />}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Form.Group>
            </>
          )}
          <div className={stylesUtils.flexCenter}>
            <Button type="submit">Сохранить</Button>
          </div>
        </Form>
      )}
    </>
  );
};

export default CreateUpdatePollTagPageView;
